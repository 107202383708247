<template>
  <div>
    <b-modal :title="$t('Update invite code')" v-model="show" no-close-on-backdrop hide-footer>
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-input v-model="formData.inviteCode" :placeholder="$t('Enter a invite code required')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-button type="submit" variant="primary" block size="lg" :disabled="loading">
          {{$t("Save changes")}}
        </b-button>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import profileService from "@/services/profileService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "UpdateInviteCodeModal",
  data() {
    return {
      show: false,
      loading: false,
      formData: {
        inviteCode: ""
      }
    }
  },
  methods: {
    openModal() {
      this.show = true
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await profileService.updateInviteCode(this.formData, await this.$recaptcha('updateInviteCode'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      this.show = false;
      await dialogHelper.alert(response.message, "success");
      this.$emit("success", response.data);
    }
  }
}
</script>

<style scoped>

</style>
