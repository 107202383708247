<template>
  <div class="page page-account">
    <AccountHeader />
    <LoadingPanel v-if="loading"/>
    <div class="acc-menu px-3" v-else-if="account">
      <div class="acc-security-point d-flex align-items-center" v-if="security">
        <div class="sec-circle mr-2">
          <progress-circle :diameter="90"
                           :completed-steps="security.complete"
                           circle-color="#E6E6E6"
                           :start-color="security.color"
                           :stop-color="security.color"
                           :circle-width="8"
                           percent-color="#333333"
                           :total-steps="3">
            <div class="sec-text">{{security.text}}</div>
          </progress-circle>
        </div>
        <div class="sec-content">
          <h3>{{$t("Security level")}}</h3>
          <div>{{$t("Your account may now be under threat. Please complete the information below")}}</div>
        </div>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i class="fa-solid fa-circle-check text-success"></i>
          </div>
          <div class="acc-menu-item-content">
            <label>{{$t("Display name")}}</label>
            <div class="description">{{account.displayName}}</div>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i :class="account.hasUsername ? 'fa-solid fa-circle-check text-success' : 'fa-solid fa-circle-exclamation text-danger'"></i>
          </div>
          <div class="acc-menu-item-content">
            <label>{{$t("Username")}}</label>
            <div class="description">{{account.hasUsername ? account.username : $t("Have not username")}}</div>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i :class="account.hasEmail ? 'fa-solid fa-circle-check text-success' : 'fa-solid fa-circle-exclamation text-danger'"></i>
          </div>
          <div class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Email")}}</label>
              <div class="description">{{account.hasEmail ? account.email : $t("Have not email")}}</div>
            </div>
            <a href="#" class="btn-update" @click.prevent="$router.push({name: 'AccountUpdateEmail'})">{{$t("Update")}}</a>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i :class="account.hasPhone ? 'fa-solid fa-circle-check text-success' : 'fa-solid fa-circle-exclamation text-danger'"></i>
          </div>
          <div class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Phone number")}}</label>
              <div class="description">{{account.hasPhone ? account.phone : $t("Have not phone number")}}</div>
            </div>
            <a href="#" class="btn-update" @click.prevent="$router.push({name: 'AccountUpdatePhone'})">{{$t("Update")}}</a>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i class="fa-solid fa-circle-check text-success"></i>
          </div>
          <div class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Password")}}</label>
              <div class="description">*******</div>
            </div>
            <a href="#" class="btn-update" @click.prevent="$router.push({name: 'AccountChangePassword'})">{{$t("Change")}}</a>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i class="fa-solid fa-circle-check text-success"></i>
          </div>
          <div class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Your invite code")}}</label>
              <div class="description">
                {{account.inviteCode}}
                <input type="hidden" id="hideInviteCode" :value="account.inviteCode"/>
              </div>
            </div>
            <a href="#" class="btn-update" @click.prevent="openMyInviteCodeModal()">{{$t("View")}}</a>
          </div>
        </a>
      </div>
      <div class="acc-menu-item">
        <a href="#" class="d-flex">
          <div class="acc-menu-item-icon">
            <i class="fa-solid fa-circle-check text-success"></i>
          </div>
          <div v-if="account && account.inviter" class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Refer invite code")}}</label>
              <div class="description">
                {{account.inviter.inviteCode}} <span style="color: black">{{$t("from friend")}}</span> {{account.inviter.username}}
              </div>

            </div>
            <a href="#" class="btn-update" @click.prevent="$insertToClipboard(account.inviter.inviteCode)">{{$t("Copy")}}</a>
          </div>
          <div v-else class="acc-menu-item-content d-flex align-items-center justify-content-between">
            <div>
              <label>{{$t("Refer invite code")}}</label>
              <div class="description">
                {{$t("Empty")}}
              </div>
            </div>
            <a href="#" class="btn-update" @click.prevent="openUpdateInviteCodeModal()">{{$t("Update")}}</a>
          </div>
        </a>
      </div>
      <div class="text-center py-3">
        <b-button variant="primary" @click="signOut()">{{$t("Sign out")}}</b-button>
      </div>
      <UpdateInviteCodeModal ref="updateInviteCodeModal" @success="updateInviter"/>
      <MyInviteCodeModal ref="myInviteCodeModal" :invite-code="account.inviteCode" />
    </div>
    <div class="py-3" v-else>
      <ErrorPanel :message="message" @retry="load()" />
    </div>
  </div>
</template>

<script>
import AccountHeader from "@/components/headers/AccountHeader";
import profileService from "@/services/profileService";
import { ProgressCircle } from 'vue-progress-circle';
import dialogHelper from "@/helpers/dialogHelper";
import UpdateInviteCodeModal from "@/components/modals/UpdateInviteCodeModal";
import MyInviteCodeModal from "@/components/modals/MyInviteCodeModal";

export default {
  name: "Index",
  components: {
    MyInviteCodeModal,
    UpdateInviteCodeModal,
    AccountHeader,
    ProgressCircle
  },
  data() {
    return {
      account: null,
      loading: true,
      message: "",
      security: null
    }
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const response = await profileService.account();
      if (!response) {
        this.loading = false;
        this.message = this.$t("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        this.loading = false;
        this.message = response.message;
        return;
      }

      const data = response.data;
      data.hasEmail = data.email && data.email.length;
      data.hasPhone = data.phone && data.phone.length;
      data.hasUsername = data.username && data.username.length;
      this.account = data;
      this.loading = false;
      this.security = {
        complete: data.securityLevel,
        text: this.$t(data.securityLevel < 2 ? "Weak" : (data.securityLevel < 3 ? "Medium" : "Good")),
        color: data.securityLevel < 2 ? "red" : (data.securityLevel < 3 ? "yellow" : "green")
      }
    },
    async signOut() {
      if (!await dialogHelper.confirm("Are you ready to sign out"))
        return;

      const store = this.$store;
      await store.dispatch("setAccount", null);
      await store.dispatch("setAccessToken", null);
      this.$bridge.$emit("accountLogin");
      await this.$router.push({name: "Home"});
    },
    copyInviteCode() {
      this.$insertToClipboard("123123")
    },
    openUpdateInviteCodeModal() {
      this.$refs.updateInviteCodeModal.openModal();
    },
    updateInviter(inviter) {
      this.account.inviter = inviter;
    },
    openMyInviteCodeModal() {
      this.$refs.myInviteCodeModal.openModal();
    }
  }
}
</script>

<style scoped lang="scss">
.acc-menu {
  .acc-security-point {
    padding: 15px 0;
    border-bottom: 1px solid #eee;

    .sec-circle {
      .sec-text {
        font-size: 18px;
        color: #777;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .sec-content {
      flex: 1;

      h3 {
        font-size: 18px;
        margin-bottom: 3px;
        font-weight: 600;
      }
    }
  }

  .acc-menu-item {
    padding: 15px 0;
    border-bottom: 1px solid #eee;

    .acc-menu-item-icon {
      margin-right: 10px;
      font-size: 16px;
    }

    .acc-menu-item-content {
      flex: 1;

      label {
        font-size: 16px;
        margin-bottom: 0;
      }

      .description {
        font-size: 14px;
        color: #8e8e8e;
      }

      .btn-update {
        color: #f04406;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
</style>
