<template>
  <div>
    <b-modal :title="$t('Your invite code')" v-model="show" no-close-on-backdrop hide-footer>
      <b-form-group>
        <label class="form-control-label">{{ $t("Invite code") }}</label>
        <b-input-group>
          <b-form-input v-model="inviteCode" id="inputInviteCode" readonly/>
          <b-input-group-append>
            <b-button @click="$insertToClipboard(inviteCode, 'inputInviteCode')">{{$t("Copy")}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <label class="form-control-label">{{ $t("Invite link") }}</label>
        <b-input-group>
          <b-form-input v-model="inviteUrl" id="inputInviteUrl" readonly/>
          <b-input-group-append>
            <b-button @click="$insertToClipboard(inviteUrl, 'inputInviteUrl')">{{$t("Copy")}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group class="pt-2">
        <div class="font-weight-bold text-danger">
          NOTE:
        </div>
        <div>
          1. Copy invite code to update friend's refer code.
        </div>
        <div>
          2. Copy invite URL and send to friend to register new account.
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "MyInviteCodeModal",
  props: ['inviteCode'],
  data() {
    return {
      show: false,
      inviteUrl: ""
    }
  },
  methods: {
    openModal() {
      this.inviteUrl = window.location.origin + "/register?inviteCode=" + this.inviteCode;
      this.show = true;
    }
  }
}
</script>

<style scoped>

</style>
